<template>
  <mentors-section
    :key="updateKey"
    :members-organizations="membersOrganizations"
    @setPage="setMembersOrganizationsPage"
  />
</template>
<script>
import { MentorsSection } from "@/components/contact/sections";
import { initialListingModel } from "@/utils";
import { talentClient } from "@/api";
export default {
  name: "ContactMentorsPage",
  components: {
    MentorsSection,
  },
  data() {
    return {
      membersOrganizations: initialListingModel(10),
      updateKey: 0,
    };
  },
  metaInfo() {
    return {
      title: `Карточка контакта / Наставники`,
    };
  },
  computed: {
    talentId() {
      return +this.$route.params.id;
    },
  },
  activated() {
    this.getMentors();
    this.updateKey += 1;
  },
  methods: {
    async setMembersOrganizationsPage(page) {
      this.membersOrganizations.page = page;
    },
    async getMentors() {
      const { talentId } = this;
      this.membersOrganizations.pending = true;
      const limit = 50;
      let communications = [];

      const getCommunications = async (offset = 0) => {
        try {
          const { data } = await talentClient({
            method: "GET",
            url: `/users/${talentId}/organizations/members/`,
            params: {
              type: "student",
              limit,
              offset,
            },
          });

          if (data.count === 0) return;

          communications = [...communications, ...data.results];

          if (data.results.length < limit) {
            const newList = communications.reduce((acc, item) => {
              if (item.mentor && item.event) {
                if (!acc[item.organization]) {
                  acc[item.organization] = {};
                  acc[item.organization].events = {};
                  acc[item.organization].events[item.event] = [item.mentor];
                  acc[item.organization].orgId = item.organization;
                } else {
                  if (!acc[item.organization]?.events[item.event]) {
                    acc[item.organization].events[item.event] = [item.mentor];
                  } else {
                    acc[item.organization].events[item.event].push(item.mentor);
                  }
                }
              }
              return acc;
            }, {});
            const list = Object.values(newList);
            this.membersOrganizations = {
              ...this.membersOrganizations,
              total: list.length,
              list,
            };
            return;
          }

          await getCommunications(offset + limit);
        } catch (error) {
          this.membersOrganizations.error = error.message;
        }
      };

      await getCommunications();
      this.membersOrganizations.pending = false;
    },
  },
};
</script>
